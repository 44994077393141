import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import styled from 'styled-components';

import { Typography } from '../../../Typography/Typography';
import { StyledRadixGroupItem, StyledRadixGroupItemIndicator } from '../Radio';

import type { RadioGroupProps as RadixRadioGroupProps } from '@radix-ui/react-radio-group';

export interface RadioGroupItemProps {
  label: string;

  /**
   * Note: Each option is rendered as an invisible input with `type="radio"` (if within a form context). The `value`
   * property of an option is also used as the input's `id`.
   */
  value: string;
  description: string;
  subDescription?: string;
  descriptionColor?: string;
}

export interface RadioButtonGroupProps extends Omit<RadixRadioGroupProps, 'dir' | 'loop' | 'name' | 'orientation'> {
  /** This component represents a group of inputs with the same `name` prop. */
  name: string;
  label: string;
  options: RadioGroupItemProps[];
  isValid?: boolean;
  error?: string;
  'data-testid'?: string;
}

const StyledRadixRadioButtonGroupRoot = styled(RadixRadioGroup.Root)`
  --radioColor: ${({ theme }) => theme.colors.capsuleBlue50};
  &[aria-invalid='true'] {
    --radioColor: ${({ theme }) => theme.colors.capsuleRed60};
    & > label {
      border-color: ${({ theme }) => theme.colors.capsuleRed60};
    }
  }

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.s3};
`;

const RadioButtonGroupCard = styled(props => <Typography {...props} />)`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.space.s4} ${({ theme }) => theme.space.s4};
  gap: ${({ theme }) => theme.space.s2};
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.radii.r100};
  cursor: pointer;
  box-shadow: 0px 2px 6px 0px rgba(153, 191, 188, 0.24), 0px 0px 1px 0px rgba(110, 120, 129, 0.16);
  margin: 1px; /** we need 1px of margin on each option so that the box-shadow doesn't get cropped */
  user-select: none;

  transition: background-color ${({ theme }) => theme.animation.typical},
    box-shadow ${({ theme }) => theme.animation.typical}, color ${({ theme }) => theme.animation.typical};
  & * {
    cursor: pointer;
  }

  &:has(> input:checked) {
    background-color: ${({ theme }) => theme.colors.capsuleGreen10};
    border-color: ${({ theme }) => theme.colors.capsuleGreen80};
  }

  @media (hover: hover) {
    &:hover > ${StyledRadixGroupItem} {
      border-color: var(--radioColor);
    }
    &:hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.capsuleGreen10};
      // border-color: ${({ theme }) => theme.colors.capsuleGreen80};
    }
  }
`;

const LabelContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
const CustomTypography = styled(props => <Typography {...props} />)`
  color: ${({ theme, color }) => theme.colors[color]} !important;
`;

export const RadioButtonGroup = ({ name, options, error, isValid = true, ...props }: RadioButtonGroupProps) => {
  const errorId = `${name}-radio-button-group-error`;
  return (
    <>
      <StyledRadixRadioButtonGroupRoot
        name={name}
        {...props}
        aria-invalid={!isValid}
        aria-describedby={isValid ? undefined : errorId}
      >
        {options.map(({ label, value, description, subDescription, descriptionColor }) => {
          const identifier = `${name}_${value}`;

          return (
            <RadioButtonGroupCard key={identifier} as="label" htmlFor={identifier}>
              <LabelContainer>
                <CustomTypography variant="heading3" color={'capsuleBlue50'}>
                  {label}
                </CustomTypography>
                <CustomTypography variant="body" color={descriptionColor ?? 'capsuleGray50'}>
                  {description}
                </CustomTypography>
                <CustomTypography variant="small" color={descriptionColor ?? 'capsuleGray50'}>
                  {subDescription}
                </CustomTypography>
              </LabelContainer>
              <StyledRadixGroupItem value={value} id={identifier}>
                <StyledRadixGroupItemIndicator />
              </StyledRadixGroupItem>
            </RadioButtonGroupCard>
          );
        })}
        <CustomTypography variant="body" color={'capsuleRed60'} aria-live="polite" id={errorId}>
          {!isValid && !error && 'Please select an option'}
          {!isValid && error}
        </CustomTypography>
      </StyledRadixRadioButtonGroupRoot>
    </>
  );
};
