import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import styled, { css } from 'styled-components';

import { Typography } from '../../Typography/Typography';

import type { RequiredLabelProp } from '../types';
import type { RadioGroupProps as RadixRadioGroupProps } from '@radix-ui/react-radio-group';

export interface RadioItem {
  label: string;

  /**
   * Note: Each option is rendered as an invisible input with `type="radio"` (if within a form context). The `value`
   * property of an option is also used as the input's `id`.
   */
  value: string;

  /**
   * @deprecated The `name` property is defined at the top level of the component.
   */
  name?: never;

  /**
   * @deprecated Use *ByLabelText queries.
   * @see https://testing-library.com/docs/queries/bylabeltext
   */
  'data-testid'?: never;
}

export interface RadioProps extends Omit<RadixRadioGroupProps, 'dir' | 'loop' | 'name'> {
  /** This component represents a group of inputs with the same `name` prop. */
  name: string;

  // TODO: Properly integrate `RequiredLabelProp` and be prepared for a visible group label (fieldset legend).
  label: {
    content: RequiredLabelProp['label']['content'];
    isHidden: true;
  };
  options: RadioItem[];
  isValid?: boolean;
  'data-testid'?: string;
}

const StyledRadixRadioGroupRoot = styled(RadixRadioGroup.Root)`
  --radioColor: ${({ theme }) => theme.colors.capsuleBlue50};
  &[aria-invalid='true'] {
    --radioColor: ${({ theme }) => theme.colors.capsuleRed60};
  }

  display: flex;
  flex-direction: ${({ orientation }) => (orientation === 'horizontal' ? 'row' : 'column')};
  gap: ${({ theme }) => theme.space.s5};
`;

export const StyledRadixGroupItem = styled(RadixRadioGroup.Item)`
  all: unset;
  background-color: ${({ theme }) => theme.colors.transparent};
  width: 2rem;
  height: 2rem;
  flex: 0 0 2rem; /** this prevents the circle from morphing into oval shapes when zoomed in */
  border-radius: ${({ theme }) => theme.radii.circle};
  border: 0.1rem solid ${({ theme }) => theme.colors.transparent};
  box-shadow: 0 0 0 0.1rem var(--radioColor);

  &:focus-visible {
    box-shadow: 0 0 0 0.1rem var(--radioColor);
  }
`;

const RadioGroupItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.space.s3};

  cursor: pointer;
  & * {
    cursor: pointer;
  }

  @media (hover: hover) {
    &:hover > ${StyledRadixGroupItem} {
      border-color: var(--radioColor);
    }
  }
`;

export const StyledRadixGroupItemIndicator = styled(RadixRadioGroup.Indicator)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  &::after {
    content: '';
    display: block;
    border-radius: ${({ theme }) => theme.radii.circle};
    background: var(--radioColor);

    /**
     * This essentially follows the "3px of padding" described in Figma, but with zooming taken
     * into consideration.
     */
    width: calc(100% - 0.3rem);
    height: calc(100% - 0.3rem);
    position: absolute;
    top: 0.15rem;
    left: 0.15rem;
  }
`;

export const Radio = ({ name, options, isValid = true, ...props }: RadioProps) => {
  return (
    <StyledRadixRadioGroupRoot name={name} {...props} aria-invalid={!isValid}>
      {options.map(({ label, value }) => {
        const identifier = `${name}_${value}`;

        return (
          <RadioGroupItemContainer key={identifier}>
            <StyledRadixGroupItem value={value} id={identifier}>
              <StyledRadixGroupItemIndicator />
            </StyledRadixGroupItem>

            <Typography
              as="label"
              htmlFor={identifier}
              css={css`
                color: var(--radioColor);
                user-select: none;
                width: 100%;
              `}
            >
              {label}
            </Typography>
          </RadioGroupItemContainer>
        );
      })}
    </StyledRadixRadioGroupRoot>
  );
};
